import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledClient, ClientData, Statistics, Main } from "../styled/Client";
import { Menu } from "../styled/Admin";
import { NameCapital } from "../styled/Admin";
import arrow from "../assets/icons/back-arrow.svg";
import call from "../assets/icons/call-client.svg";
import mail from "../assets/icons/mail-client.svg";
import { ClientType } from "../interfaces/Visitations";
import bar from "../assets/icons/bar-client.svg";
import time from "../assets/icons/time.svg";
import overall from "../assets/icons/overall-sum.svg";
import { PERSONAL_STATS, HISTORY_VISIT } from "../gql/query/personalStats";
import { END_SESSION_ADMIN } from "../gql/mutation/client";
import { useMutation, useQuery } from "@apollo/client";
import { getTimeDifference } from "../utils/timeDifference";
import {
  PersonalStatsType,
  HistoryVisitType,
  HistoryVisit,
  Instituion,
  Completed,
} from "../interfaces/Client";
import forward_up from "../assets/icons/forward-up.svg";
import forward_down from "../assets/icons/forward-down.svg";
import { Button } from "../styled/Admin";
import MenuComponent from "../component/MenuComponent";
import { onScroll } from "../utils/onScroll";
import { Loaded, DarkBackground } from "../styled/Client";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";

interface EndSession {
  endSessionForAdmin: HistoryVisit;
}

const Client = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const state = location.state as ClientType;
  const client = state;
  console.log("Client", client);
  const num = client.phoneNumber
    ? `+${client.phoneNumber[0]} ${client.phoneNumber.substr(
        1,
        3
      )} ${client.phoneNumber.substr(4, 3)} ${client.phoneNumber.substr(
        7,
        2
      )} ${client.phoneNumber.substr(9, 2)}`
    : "No phone";

  const [sortBy, setSortBy] = useState("Date");

  const [everywhere, setEverywhere] = useState(false);
  const [sorted, setSorted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [historyCompleted, setHistoryCompleted] = useState<HistoryVisit[]>([]);

  const sortRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const instRef = useRef<HTMLDivElement>(null);
  const scrollActiveRef = useRef<HTMLDivElement>(null);
  const scrollRefMain = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const [actives, setActives] = useState<HistoryVisit[]>([]);
  const [institutions, setInstitutions] = useState<Instituion[]>([]);
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [institution, setInstitution] = useState<Instituion>();

  const [endSession, { data: endData }] =
    useMutation<EndSession>(END_SESSION_ADMIN);

  const [insts, setInsts] = useState(false);

  const { data } = useQuery<PersonalStatsType>(PERSONAL_STATS, {
    variables: { clientId: client._id },
  });

  const { data: clientData } = useQuery<HistoryVisitType>(HISTORY_VISIT, {
    variables: {
      clientId: client._id,
      isActive: false,
      page: page,
      sortBy: sortBy,
    },
  });

  const { data: clientActive } = useQuery<HistoryVisitType>(HISTORY_VISIT, {
    variables: {
      clientId: client._id,
      isActive: true,
      page: activePage,
      sortBy: "Date",
    },
  });

  const getHistoryLeed = () => {
    if (clientData) {
      for (const session of clientData.getVisitationsOfAClientForAdminPanel) {
        const foundEl = historyCompleted.filter((it) => it._id === session._id);
        if (foundEl.length < 1) {
          historyCompleted.push(session);
        }
      }
      setHistoryCompleted([...historyCompleted]);
    }
  };

  const getClientActive = () => {
    if (clientActive) {
      for (const session of clientActive.getVisitationsOfAClientForAdminPanel) {
        const foundEl = actives.filter((it) => it._id === session._id);
        if (foundEl.length < 1) {
          actives.push(session);
        }
      }

      setActives([...actives]);
    }
  };

  useEffect(() => {
    document.title = t("titleClient");
  }, []);

  useEffect(() => {
    getHistoryLeed();
  }, [clientData]);

  useEffect(() => {
    getClientActive();
  }, [clientActive]);

  useEffect(() => {
    if (endData) {
      const res = actives.filter(
        (item) => item._id === endData.endSessionForAdmin._id
      );
      setLoading(false);
      if (res) {
        if (actives.length > 1)
          setActives([
            ...actives.filter(
              (item) => item._id !== endData.endSessionForAdmin._id
            ),
          ]);
        else if (actives.length === 1) {
          actives.length = 0;
          setActives(actives);
        }
      }
    }
  }, [endData]);

  useEffect(() => {
    if (clientData) {
      for (const inst of clientData.getVisitationsOfAClientForAdminPanel) {
        const foundInd = institutions.find(
          (it) => it._id === inst.institution!._id
        );
        if (foundInd === undefined) {
          institutions.push(inst.institution!);
        }
      }
      setInstitutions(institutions);
    }
  }, [clientData]);

  useEffect(() => {
    if (actives.length === 0) {
      setIsActiveSession(false);
    } else setIsActiveSession(true);
  }, [actives]);

  const changeSortType = () => {
    historyCompleted.length = 0;
    setHistoryCompleted(historyCompleted);
  };

  // const showSortBy = () => {
  //     const ref = sortRef.current;

  //     const children = Array.from(ref?.children!);

  //     if (ref?.getAttribute('class')!.indexOf('shadow') === -1)
  //         ref?.classList.add('shadow');
  //     else ref?.classList.remove('shadow');

  //     if (children[1].getAttribute('class')!.indexOf('inactive') === -1) {
  //         children[1].classList.remove('active');
  //         children[1].classList.add('inactive');
  //     }
  //     else if(children[1].getAttribute('class')!.indexOf('inactive') !== -1){
  //         children[1].classList.remove('inactive');
  // 		children[1].classList.add('active');
  //     }
  // };

  const showInsts = () => {
    const ref = instRef.current;

    const children = Array.from(ref?.children!);

    if (children[1].getAttribute("class")!.indexOf("inactive") === -1) {
      children[1].classList.remove("active");
      children[1].classList.add("inactive");
    } else if (children[1].getAttribute("class")!.indexOf("inactive") !== -1) {
      children[1].classList.remove("inactive");
      children[1].classList.add("active");
    }
  };

  const getTimeDifferenceClient = (date1, date2) => {
    return getTimeDifference(date1, date2);
  };

  const groupSortPage = (sortBy: string, variantScale: number) => {
    if (variantScale === 1) {
      setSortBy(sortBy);
      setPage(1);
    } else if (variantScale === 2) {
      setSortBy(sortBy);
      setPage(1);
      changeSortType();
    }
  };

  return (
    <>
      {loading ? (
        <DarkBackground disappear={!loading}>
          <LoadingOverlay
            text={t("endedSessions")}
            active={true}
            spinner={true}
          ></LoadingOverlay>
        </DarkBackground>
      ) : (
        <StyledClient
          ref={scrollRefMain}
          onScroll={() => onScroll(scrollRefMain, historyCompleted, setPage)}
        >
          <Menu>
            <MenuComponent />
          </Menu>

          <Main>
            <div onClick={() => navigate(-1)} className="go-back">
              <img src={arrow} alt="" />
              {t("back")}
            </div>

            <div className="data-header">
              <ClientData>
                <div className="client-data">
                  <div className="client-data-capital">
                    {client.photoURL ? (
                      <img
                        width={"83px"}
                        height={"83px"}
                        style={{
                          borderRadius: "50%",
                          margin: "0 15px",
                        }}
                        src={client.photoURL.XL}
                      />
                    ) : (
                      <NameCapital
                        active={false}
                        style={{
                          width: "100%",
                          maxWidth: "53px",
                          minHeight: "53px",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          {client?.fullName &&
                            client?.fullName[0].toUpperCase()}
                        </span>
                      </NameCapital>
                    )}
                  </div>
                  <div className="client">
                    <p className="fullname">{client.fullName}</p>
                  </div>
                </div>
                <div className="call-mail-block">
                  <div className="call-mail">
                    <img src={call} alt="" />
                    <span>
                      {client.phoneNumber ? (
                        <a href={`tel:+${num}`}>{num}</a>
                      ) : (
                        <p>{num}</p>
                      )}
                    </span>
                  </div>
                  <div
                    className={`call-mail ${
                      client.email === null ? "nomail" : ""
                    }`}
                  >
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.5 6.14942C0.5 5.04485 1.39543 4.14941 2.5 4.14941H18.5C19.6046 4.14941 20.5 5.04484 20.5 6.14941V16.3161C20.5 17.4206 19.6046 18.3161 18.5 18.3161H2.5C1.39543 18.3161 0.5 17.4207 0.5 16.3161V6.14942Z"
                        stroke="#979797"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.92131 5.45228C1.41709 4.99008 1.74409 4.14941 2.4281 4.14941H18.5719C19.2559 4.14941 19.5829 4.99008 19.0787 5.45228L12.5272 11.4578C11.3802 12.5092 9.61979 12.5092 8.47283 11.4578L1.92131 5.45228Z"
                        stroke="#979797"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>
                      {client.email === null ? (
                        "No email"
                      ) : (
                        <a href={`mailto:${client.email}`}>{client.email}</a>
                      )}
                    </span>
                  </div>
                </div>
              </ClientData>
              <Statistics>
                <div className="average-price-time">
                  <div className="average-price">
                    <div className="stats-icon">
                      <img src={bar} alt="" />
                    </div>
                    <div className="price-visit">
                      <h2>
                        {
                          data?.getPersonalStatsByClientIdForOwner
                            .averageMoneySpent
                        }{" "}
                        ₸
                      </h2>
                      <p>{t("averagePricePerVisit")}</p>
                    </div>
                  </div>
                  <div className="average-time">
                    <div className="stats-icon">
                      <img src={time} alt="" />
                    </div>
                    <div className="time-visit">
                      <h2>
                        {
                          data?.getPersonalStatsByClientIdForOwner
                            .averageTimeSpent
                        }{" "}
                        {t("min")}
                      </h2>
                      <p>{t("averageTimePerVisit")}</p>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="overall-sum">
                    <div className="stats-icon">
                      <img src={overall} alt="" />
                    </div>
                    <div>
                      <h2>
                        {data?.getPersonalStatsByClientIdForOwner.moneySpent} ₸
                      </h2>
                      <p>{t("overallSum")}</p>
                    </div>
                  </div>
                </div>
              </Statistics>
            </div>
            {isActiveSession && (
              <div className="active-sessions">
                <h2>{t("activeSessions")}</h2>
                <div
                  className="session-scroll"
                  ref={scrollActiveRef}
                  onScroll={() =>
                    onScroll(scrollActiveRef, actives, setActivePage)
                  }
                >
                  {actives.map((active) => {
                    const shortId =
                      active._id.substr(1, 3).toUpperCase() +
                      "-" +
                      active._id.substr(active._id.length - 2).toUpperCase();

                    const date = new Date(active.startTime);
                    const hour = date.getHours();
                    const minute = date.getMinutes();
                    const [timeDiff, minutesSpent] = getTimeDifferenceClient(
                      new Date().toISOString(),
                      active.startTime
                    );

                    return (
                      <div className="story-session" key={active._id}>
                        <div className="story-session-id">
                          <div className="session-name-address">
                            <p>
                              {
                                active.institution.location.address.split(
                                  ","
                                )[0]
                              }
                            </p>
                            <h3>{active.institution.name}</h3>
                          </div>
                          <div className="short-id">
                            <span>{shortId}</span>
                          </div>
                          <div className="service-name">
                            <span>{active.service?.name}</span>
                          </div>
                        </div>
                        <div className="stats-number">
                          <div className="stats-number-block">
                            <div className="start-time">
                              <div className="block-info">
                                <p className="paragraph">{t("startTime")}</p>
                                <p className="time">
                                  {hour < 10 ? "0" + hour : hour} :{" "}
                                  {minute < 10 ? "0" + minute : minute}
                                </p>
                              </div>
                            </div>
                            <div className="duration">
                              <div className="block-info">
                                <p className="paragraph">{t("duration")}</p>
                                <p className="time">{timeDiff}</p>
                              </div>
                            </div>
                          </div>

                          <div className="price">
                            <div className="block-info">
                              <p className="paragraph">{t("sum")}</p>
                              <p className="time">
                                {active.price ? active.price : 0} ₸
                              </p>
                            </div>
                          </div>
                        </div>
                        <Button
                          onClick={() => {
                            endSession({
                              variables: {
                                sessionId: active._id,
                                clientId: client._id,
                              },
                            });
                            setLoading(true);
                          }}
                          backgroundColor={"#FFE6E6"}
                          color={"#F40F0F"}
                          fontSize={16}
                        >
                          <span>{t("end")}</span>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="story-completed">
              <div className="story-visit-block">
                <h2>{t("historyVisits")}</h2>
                <div className="sort-by">
                  <div
                    className="everywhere"
                    onClick={() => {
                      setEverywhere(!everywhere);
                      setInsts(!insts);
                    }}
                  >
                    <div className="everywhere-block">
                      {institution === undefined ? (
                        <span className="insts">{t("everywhere")}</span>
                      ) : (
                        <span className="insts">{institution.name}</span>
                      )}
                      <img
                        src={!everywhere ? forward_up : forward_down}
                        alt=""
                      />
                    </div>

                    {insts && (
                      <>
                        {institution !== undefined ? (
                          <span
                            className="insts margin-top"
                            onClick={() => setInstitution(undefined)}
                          >
                            {t("everywhere")}
                          </span>
                        ) : (
                          ""
                        )}
                        {institutions.map((el) => {
                          if (institution?._id !== el._id) {
                            return (
                              <span
                                key={el._id}
                                className="insts margin-top"
                                onClick={() => setInstitution(el)}
                              >
                                {el.name}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </>
                    )}
                  </div>
                  <div
                    className="sort-type"
                    ref={sortRef}
                    onClick={() => {
                      setSorted(!sorted);
                    }}
                  >
                    {sortBy === "Date" ? (
                      <span className="by-date">
                        {t("recentOnesFirst")}{" "}
                        <img
                          style={{ marginLeft: "4px" }}
                          src={!sorted ? forward_up : forward_down}
                          alt=""
                        />
                      </span>
                    ) : sortBy === "Price" ? (
                      <span className="by-price">
                        {t("byPrice")}{" "}
                        <img
                          style={{ marginLeft: "4px" }}
                          src={!sorted ? forward_up : forward_down}
                          alt=""
                        />
                      </span>
                    ) : (
                      <span className="by-time">
                        {t("byDuration")}{" "}
                        <img
                          style={{ marginLeft: "4px" }}
                          src={!sorted ? forward_up : forward_down}
                          alt=""
                        />
                      </span>
                    )}
                    {sorted && (
                      <>
                        {sortBy === "Price" ? (
                          <span
                            className="by-date margin-top"
                            onClick={() => groupSortPage("Date", 2)}
                          >
                            {t("recentOnesFirst")}{" "}
                          </span>
                        ) : (
                          <span
                            className="by-price margin-top"
                            onClick={() => groupSortPage("Price", 2)}
                          >
                            {t("byPrice")}
                          </span>
                        )}
                        {sortBy === "Duration" ? (
                          <span
                            className="by-date margin-top"
                            onClick={() => groupSortPage("Date", 2)}
                          >
                            {t("recentOnesFirst")}
                          </span>
                        ) : (
                          <span
                            className="by-time margin-top"
                            onClick={() => groupSortPage("Duration", 1)}
                          >
                            {t("byDuration")}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="completed-sessions"
                id="completed"
                onScroll={() => onScroll(scrollRef, historyCompleted, setPage)}
                ref={scrollRef}
              >
                {historyCompleted.map((item) => {
                  const shortId =
                    item._id.substr(1, 3).toUpperCase() +
                    "-" +
                    item._id.substr(item._id.length - 2).toUpperCase();

                  const date = new Date(item.startTime);

                  const [timeDiff, minutesSpent] = getTimeDifference(
                    item.endTime,
                    item.startTime
                  );
                  const hour = date.getHours();
                  const minute = date.getMinutes();

                  const day = date.getDate();
                  const month = date.getMonth();
                  const year = date.getFullYear();

                  return (
                    <div key={item._id} className="story-session">
                      <div className="story-session-id">
                        <div className="session-name-address">
                          <p>
                            {item.institution.location.address.split(",")[0]}
                          </p>
                          <p className="date-session">
                            {day < 10 ? "0" + day : day}.
                            {month + 1 < 10 ? "0" + (month + 1) : month}.{year}
                          </p>
                          <h3>{item.institution.name}</h3>
                        </div>
                        <div className="short-id">
                          <span>{shortId}</span>
                        </div>
                        <div className="service-name">
                          <span>{item.service?.name}</span>
                        </div>
                      </div>
                      <div className="stats-number">
                        <div className="stats-number-block">
                          <div className="start-time">
                            <div className="block-info">
                              <p className="paragraph">{t("startTime")}</p>
                              <p className="time">
                                {hour < 10 ? "0" + hour : hour} :{" "}
                                {minute < 10 ? "0" + minute : minute}
                              </p>
                            </div>
                          </div>
                          <div className="duration">
                            <div className="block-info">
                              <p className="paragraph">{t("duration")}</p>
                              <p className="time">{timeDiff}</p>
                            </div>
                          </div>
                        </div>
                        <div className="price">
                          <div className="block-info">
                            <p className="paragraph">{t("sum")}</p>
                            <p className="time">{item.price} ₸</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Main>
        </StyledClient>
      )}
    </>
  );
};

export default Client;
